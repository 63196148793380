<template>
  <div id="project-list">
    <v-card>
      <v-divider class="mt-4"></v-divider>
      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field v-model="searchQuery"
                      placeholder="Search"
                      outlined
                      hide-details
                      dense
                      class="project-search me-3 mb-4">
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <!--<v-btn color="primary"
                 class="mb-4 me-3"
                 @click.stop="isAddNewprojectSidebarActive = !isAddNewprojectSidebarActive">
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Add New project</span>
          </v-btn>-->
        </div>
      </v-card-text>
      <!-- table -->
      <v-data-table v-model="selectedRows"
                    :headers="tableColumns"
                    :items="projectListTable"
                    :options.sync="options"
                    :server-items-length="totalProjectListTable"
                    :loading="loading"
                    show-select>
        <!-- name -->
        <template #[`item.name`]="{item}">
          <div class="d-flex align-center">
            <v-avatar :color="item.avatar ? '' : 'primary'"
                      :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
                      size="32">
              <v-img v-if="item.thumbnail"
                     :src="item.thumbnail"></v-img>
              <span v-else
                    class="font-weight-medium">{{ avatarText(item.name) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <b>{{ item.name }}</b>
              <small>{{ item.description.substring(0,100)}}</small>
            </div>
          </div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu bottom
                  left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon
                     v-bind="attrs"
                     v-on="on">
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item :to="{name:'apps-project-view',params:{id:item.id}}">
                <v-list-item-title>
                  <v-icon size="20"
                          class="me-2">
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-list-item-title>
              </v-list-item>
              <Can I="delete" a="project">
                <v-list-item @click="deleteprojectClicked(item.id, item.storageAdress)">
                  <v-list-item-title>
                    <v-icon size="20"
                            class="me-2">
                      {{ icons.mdiDeleteOutline }}
                    </v-icon>
                    <span>Delete</span>
                  </v-list-item-title>
                </v-list-item>
              </Can>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiFileDocumentOutline,
  mdiDeleteOutline,
  mdiExportVariant,
  mdiAccountOutline,
} from '@mdi/js'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'

// sidebar
import { avatarText } from '@core/utils/filter'
import projectStoreModule from '../projectStoreModule'

import useProjectsList from './useProjectsList'

import { doc, deleteDoc } from "firebase/firestore";
import { db, storage } from '@/Firebase/Firebase.service'
import { ref as storeRef, deleteObject, list, listAll } from "firebase/storage";
import { Can, abilitiesPlugin } from '@casl/vue';

  export default {
    components: {
      Can
    },
    methods: {
      async deleteprojectClicked(id, storePath) {
        //document deleted, now to remove stuff in firestore
        deleteDoc(doc(db, id)).then(() => {
          //document deleted, get storageReference
          var storeFolderRef = storeRef(storage, storePath)
          //delete files for
          new Promise(async (resolve, reject) => {
            try {
              await this.deleteFolderRecursive(storePath);
              resolve("OK")
            } catch (e) {
              reject("fail")
            }
          }).then(() => {
            console.log("all deleted")
          }).catch((err) => {
            console.log(err)
          })
        }).catch((err) => {
          console.log(err)
        })
      },
      async deleteFolderRecursive(folderPath) {
        const ref = storeRef(storage, folderPath)
        const list = await listAll(ref)
        let filesDeleted = 0

        for await (const fileRef of list.items) {
          await deleteObject(fileRef)
          filesDeleted++
        }
        for await (const folderRef of list.prefixes) {
          filesDeleted += await this.deleteFolderRecursive(folderRef.fullPath)
        }
        return filesDeleted
      },
    },
  setup() {
    const project_APP_STORE_MODULE_NAME = 'app-project'

    // Register module
    if (!store.hasModule(project_APP_STORE_MODULE_NAME)) store.registerModule(project_APP_STORE_MODULE_NAME, projectStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(project_APP_STORE_MODULE_NAME)) store.unregisterModule(project_APP_STORE_MODULE_NAME)
    })

    const {
      projectListTable,
      tableColumns,
      searchQuery,
      totalProjectListTable,
      loading,
      options,
      projectTotalLocal,
      selectedRows,
      fetchprojects,
    } = useProjectsList()

    const isAddNewprojectSidebarActive = ref(false)

    return {
      projectListTable,
      tableColumns,
      searchQuery,
      totalProjectListTable,
      loading,
      options,
      projectTotalLocal,
      isAddNewprojectSidebarActive,
      selectedRows,
      avatarText,
      fetchprojects,
      Can,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
      },
    }
  },
}
</script>

<style lang="scss">
  @import '@core/preset/preset/apps/user.scss';
</style>
