import store from '@/store'
import {
  mdiAccountCheckOutline,
  mdiAccountOutline,
  mdiAccountPlusOutline,
  mdiAccountRemoveOutline,
  mdiCogOutline,
  mdiDatabaseOutline,
  mdiDnsOutline,
  mdiPencilOutline,
} from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

export default function useProjectsList() {
  const projectListTable = ref([])

  const tableColumns = [
    { text: 'NAME', value: 'name' },
    { text: 'DATE', value: 'dateCreated' },
    { text: 'BY', value: 'uploadedByUsername' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const searchQuery = ref('')
  const totalProjectListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['date'],
    sortDesc: [true],
  })
  const selectedRows = ref([])

  // fetch data
  const fetchProjects = () => {
    store
      .dispatch('app-project/fetchProjects', {
        q: searchQuery.value,
        options: options.value,
      })
      .then(response => {
        const { filteredData, total, projectTotal } = response.data
        projectListTable.value = filteredData
        totalProjectListTable.value = total

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([searchQuery, options], () => {
    loading.value = true
    selectedRows.value = []
    fetchProjects()
  })

  return {
    projectListTable,
    tableColumns,
    searchQuery,
    totalProjectListTable,
    loading,
    options,
    selectedRows,
    fetchProjects,
  }
}
